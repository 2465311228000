
import { defineComponent, ref, watch, toRaw, computed } from 'vue';
import { message } from 'ant-design-vue';
import useModel from '@/hooks/useModel';
import {
  fetchControllingFilterByNum,
  fetchControllingFilterByPercent,
  fetchControllingFilterByNumAndId,
  fetchControllingFilterByPercentAndId
} from '@/API/checking/spotChecking';
export default defineComponent({
  name: 'App',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    samplingData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    query: {
      type: Object,
      default: () => {
        return {};
      }
    },
    statusList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  setup(props, context) {
    const positiveIntegerReg = /^[1-9]\d*$/;
    const positiveReg = /^100$|^(\d|[1-9]\d)(\.\d+)*$/;
    const type = ref('random');
    const isCondition = ref(false);
    const random = ref<string>('');
    const stratified = ref<string>('');
    const stratifiedPercent = computed(() => {
      return Number(stratified.value) / 100 + '';
    });
    const _visible = useModel(props, 'visible');
    watch(_visible, (val) => {
      if (!val) {
        random.value = '';
        stratified.value = '';
        isCondition.value = false;
        type.value = 'random';
      }
    });
    const handleConfirm = () => {
      const _query: any = { ...toRaw(props.query) };
      _query.rejectHistory = _query.rejectHistory ? '1' : '-1';
      _query.controllingSampled = '0';
      if (_query.checkStatus.length === 0) {
        _query.checkStatus = props.statusList.map((item: any) => item.name);
      }
      const ids = props.samplingData.map((item: any) => item.id);
      _query.ids = ids;
      // 分四种情况判断
      if (type.value === 'random') {
        if (!positiveIntegerReg.test(random.value)) {
          message.error('Random Sampling Size must be a positive integer.');
          return false;
        }
        if (isCondition.value) {
          fetchControllingFilterByNum(random.value, _query).then((res) => {
            context.emit('on-data-change', {
              spotCheckVinRespVOList: res
            });
            _visible.value = false;
          });
        } else {
          fetchControllingFilterByNumAndId(random.value, _query).then((res) => {
            context.emit('on-data-change', {
              spotCheckVinRespVOList: res
            });
            _visible.value = false;
          });
        }
      } else {
        if (stratified.value === '' || Number(stratified.value) === 0 || !positiveReg.test(stratified.value)) {
          message.error('Random Sampling Size must be positive numbers greater than 0 and less than or equal to 100.');
          return false;
        }
        if (isCondition.value) {
          fetchControllingFilterByPercent(stratifiedPercent.value, _query).then((res) => {
            context.emit('on-data-change', {
              spotCheckVinRespVOList: res
            });
            _visible.value = false;
          });
        } else {
          fetchControllingFilterByPercentAndId(stratifiedPercent.value, _query).then((res) => {
            context.emit('on-data-change', {
              spotCheckVinRespVOList: res
            });
            _visible.value = false;
          });
        }
      }
    };
    return {
      _visible,
      isCondition,
      type,
      random,
      stratified,
      handleConfirm
    };
  }
});
