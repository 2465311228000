
import { defineComponent, computed, ref, reactive, watch } from "vue";
import useModel from "@/hooks/useModel";
import * as API from "@/API/checking/spotChecking";
import { message } from "ant-design-vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import useThousands from "@/utils/payment/useThousands";

type Key = ColumnProps["key"];
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    confirmModalData: {
      required: true,
      type: Array,
      default: () => [],
    },
    showCheck: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["confirm-success", "confirm-cancel"],
  setup(props, { emit }) {
    const _visible = useModel(props, "visible");
    const confirmColumns = computed(() => {
      return [
        { title: "Program Code", dataIndex: "programCode" },
        { title: "Program Name", dataIndex: "programName" },
        { title: "Offer Type", dataIndex: "offerType" },
        {
          title: "Dealer No.",
          dataIndex: "dealerNo",
          customRender: useThousands(),
          align: "right",
        },
        {
          title: "VIN No.",
          dataIndex: "vinNo",
          customRender: useThousands(),
          align: "right",
        },
        {
          title: "Claim No.",
          dataIndex: "claimNo",
          customRender: useThousands(),
          align: "right",
        },
        {
          title: "Claim Amount",
          dataIndex: "claimAmount",
          align: "right",
        },
        {
          title: "Controlling Passed No.",
          dataIndex: "controllingCheckPassedNo",
          align: "right",
        },
        {
          title: "Controlling Passed Amount",
          dataIndex: "controllingCheckPassedAmount",
          align: "right",
        },
      ];
    });
    type TableData = {
      programOfferTypeId: string;
      programCode: string;
      programName: string;
      offerType: string;
      dealerNo: string;
      vinNo: string;
      claimNo: string;
    };
    const confirmData = ref<TableData[]>();
    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: TableData[];
      onChange: Function;
      columnWidth: string;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: TableData[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: "30px",
    });

    const handleCancel = () => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
      _visible.value = false;
      emit("confirm-cancel");
    };

    const handleConfirm = () => {
      if (!props.showCheck) {
        emit("confirm-success");
      } else {
        if (rowSelection.selectedRowKeys.length === 0) {
          message.warning("Please select the data first");
          return;
        }
        emit("confirm-success", rowSelection.selectedRowKeys);
      }
      handleCancel();
    };
    return {
      _visible,
      handleCancel,
      handleConfirm,
      confirmColumns,
      confirmData,
      rowSelection,
    };
  },
});
